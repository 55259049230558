/* You can add global styles to this file, and also import other style files */
@use "../node_modules/@fortawesome/fontawesome-pro/css/all.css";
@use "@angular/material" as mat;
@use "./assets/vertigo-icons/style.css";
@use "./styles/outcome-icons";
@use "./styles/palette";
@use "./styles/vertigo-typography";
@use "./styles/utils/breakpoints";
@use "./styles/components/mat-table";
@use "./styles/components/mat-chip";
@use "./styles/components/mat-button-toggle-group-nav";
@use "./styles/components/mat-card-secondary";
@use "./app/shared/components/layout/left-sidenav/left-sidenav.component.theme"
  as left-sidenav;
@use "./app/shared/components/system/incremental-number-input/incremental-number-input.component.theme"
  as incremental-number-input;
@use "./app/shared/components/dashboard/widgets/quote-report-summary-card-widget/quote-report-summary-card-widget.component.theme"
  as quote-report-summary-card-widget;
@use "./app/shared/components/layout/page-header/page-header.component.theme" as
  page-header;
@use "./app/shared/components/layout/right-sidenav/right-sidenav.component.theme"
  as right-sidenav;
@use "./app/shared/components/dashboard/widget/widget.component.theme" as widget;
@use "./app/shared/components/dashboard/labeled-row/labeled-row.component.theme"
  as labeled-row;
@use "./app/agency/submission/overview/submission-overview.component.theme" as
  agency-submission-overview;
@use "./styles/components/ngx-slider-theme" as ngx-slider;
@use "./styles/components/mat-form-field" as mat-form-field;
@use "./app/carrier/submissions/submissions-filters-drawer/submissions-filters-drawer.component"
  as submission-filters-drawer;

html,
body {
  height: 100vh;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

@include mat.core;

// Define the default theme (same as the example above).
$vertigo-primary: mat.define-palette(palette.$default-primary, 500);
$vertigo-accent: mat.define-palette(palette.$default-accent, 500);
$vertigo-warn: mat.define-palette(mat.$pink-palette);
$vertigo-theme: (
  color: (
    primary: $vertigo-primary,
    accent: $vertigo-accent,
    warn: $vertigo-warn,
    is-dark: false,
    foreground: palette.$light-theme-foreground-palette,
    background: palette.$light-theme-background-palette,
  ),
  typography: mat.define-typography-config(),
  density: 0,
);

// Define an alternate dark theme.
$vertigo-dark-primary: mat.define-palette(palette.$default-primary, 500);
$vertigo-dark-accent: mat.define-palette(palette.$dark-accent, 500);
$vertigo-dark-warn: mat.define-palette(mat.$pink-palette);
$dark-theme: (
  color: (
    primary: $vertigo-dark-primary,
    accent: $vertigo-dark-accent,
    warn: $vertigo-dark-warn,
    is-dark: true,
    foreground: palette.$dark-theme-foreground-palette,
    background: palette.$dark-theme-background-palette,
  ),
  typography: vertigo-typography.$vertigo-custom-typography,
);

// Include the default theme styles (color and default density)
@include mat.all-component-themes($vertigo-theme);
@include mat.typography-hierarchy($dark-theme);
@include mat.list-density(
  (
    density: -2,
  )
);
@include mat.form-field-density(
  (
    density: -1,
  )
);
@include mat.icon-button-density(
  (
    density: -3,
  )
);
@include mat-table.theme($vertigo-theme);
@include mat-chip.theme($vertigo-theme);
@include mat-button-toggle-group-nav.theme($vertigo-theme);
@include left-sidenav.theme($dark-theme);
@include incremental-number-input.theme($vertigo-theme);
@include quote-report-summary-card-widget.theme($vertigo-theme);
@include agency-submission-overview.theme($vertigo-theme);
@include page-header.theme($vertigo-theme);
@include right-sidenav.theme($vertigo-theme);
@include widget.theme($vertigo-theme);
@include labeled-row.theme($vertigo-theme);
@include mat-card-secondary.color($vertigo-theme);
@include mat-button-toggle-group-nav.theme($vertigo-theme);
@include ngx-slider.theme($vertigo-theme);
@include mat-form-field.theme($vertigo-theme);
@include submission-filters-drawer.theme($vertigo-theme);

.mat-toolbar.app-toolbar {
  z-index: 6;
}

.mat-mdc-tab-header {
  z-index: 3;
  background: map-get(mat.$light-theme-background-palette, "background");
}

app-toast {
  z-index: 100;
}

@each $key, $breakpoint in breakpoints.$breakpoints {
  @media #{map-get($breakpoint, "bounded-media")} {
    @if map-get(map-get($breakpoint, "app-toolbar"), "position") == sticky {
      .mat-toolbar.app-toolbar {
        position: sticky;
        top: 0;
      }

      .mat-mdc-tab-header {
        position: sticky !important;
        top: calc(64px + 110px);
      }
    }

    .app-page-header {
      position: sticky !important;
      top: #{map-get(map-get($breakpoint, "app-page-header"), "top")} !important;
    }

    .content-full-height {
      height: calc(
        100vh - #{map-get(map-get($breakpoint, "app-toolbar"), "height")}
      );
    }
  }
}

a:not(.mdc-button),
a:visited:not(.mdc-button) {
  text-decoration: none;
  color: #000;
}

[role="button"] {
  cursor: pointer;
}

.dense {
  &.mat-mdc-form-field {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

    .mat-mdc-text-field-wrapper {
      height: 32px;
    }

    .mdc-text-field--no-label:not(
        .mdc-text-field--outlined,
        .mdc-text-field--textarea
      )
      .mat-mdc-form-field-infix {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }
}

.mat-tab-label {
  padding: 0 10px !important;
  min-width: 120px !important;
}

.mat-paginator {
  background: transparent;
}

/***** BEGIN SIDENAV CONTAINER *****/
.app-sidenav-container {
  height: 100%;

  &.mat-drawer-container {
    height: 100vh;
  }

  // Left sidenav
  .app-sidenav {
    width: 250px;

    &.mat-drawer {
      background: #000;
      background: linear-gradient(
        110deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(18, 18, 18, 1) 100%
      );
    }

    // removes app left sidenav border
    &.mat-drawer-side {
      border: 0 !important;
    }

    .mat-drawer-inner-container {
      overflow: hidden !important;

      .mat-toolbar {
        height: 160px !important;
      }
    }
  }

  // content area
  .app-sidenav-content {
    // height: 100vh;
    // overflow-x: hidden !important;

    /***** BEGIN TOOLBAR *****/
    .app-toolbar {
      .user-avatar-image {
        border-radius: 64px;
        width: 32px;
        height: 32px;
      }

      .user-wrap {
        line-height: 16px;
      }

      .global-search-overlay {
        color: map-get(mat.$light-theme-foreground-palette, "base");
        border-radius: 9999px;
      }

      .global-search-input-wrapper {
        border: 1px solid
          map-get(mat.$light-theme-foreground-palette, "divider");
        display: flex;
        align-items: center;
        border-radius: 9999px;
        line-height: 14px;
      }

      .global-search-input {
        color: map-get(mat.$light-theme-foreground-palette, "base");
        background-color: transparent;
        border: 0;
        font-size: 14px;

        &:focus {
          outline: none;
        }
      }

      .mat-mdc-autocomplete-panel.mdc-menu-surface.global-search-autocomplete {
        max-width: 400px;
      }

      .mat-toolbar form {
        line-height: 1 !important;
      }

      .toolbar-user {
        padding: 0 !important;
        border-radius: 32px !important;
      }
    }

    /******* END TOOLBAR *****/
  }
}

/***** END SIDENAV *****/

.dark-theme {
  @include mat.all-component-colors($dark-theme);
  @include mat-table.theme($dark-theme);
  @include mat-chip.color($dark-theme);
  @include left-sidenav.color($dark-theme);
  @include incremental-number-input.color($dark-theme);
  @include quote-report-summary-card-widget.color($dark-theme);
  @include agency-submission-overview.color($dark-theme);
  @include page-header.color($dark-theme);
  @include right-sidenav.color($dark-theme);
  @include widget.color($dark-theme);
  @include labeled-row.color($dark-theme);
  @include mat-card-secondary.color($dark-theme);
  @include mat-button-toggle-group-nav.color($dark-theme);
  @include submission-filters-drawer.color($dark-theme);

  a:not(.mdc-button),
  a:visited:not(.mdc-button) {
    text-decoration: none;
    color: #fff;
  }

  .app-sidenav-content {
    .app-toolbar {
      .global-search-overlay {
        color: map-get(mat.$dark-theme-foreground-palette, "base");
      }

      .global-search-input-wrapper {
        border: 1px solid map-get(mat.$dark-theme-foreground-palette, "divider");
      }

      .global-search-input {
        color: map-get(mat.$dark-theme-foreground-palette, "base");
      }
    }
  }

  .mat-mdc-tab-header {
    background: map-get(mat.$dark-theme-background-palette, "background");
  }

  // Dark scrollbar styling

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    // background: rgb(24, 24, 24);
    background: map-get(mat.$dark-theme-background-palette, "app-bar");
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    // background: rgb(77, 77, 77);
    background: map-get(mat.$dark-theme-background-palette, "background");
    border-radius: 16px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    // background: #555;
    background: map-get(mat.$dark-theme-background-palette, "background");
  }

  &.transparent-theme {
    background: #000;

    .app-sidenav-container,
    .app-sidenav-content .mat-drawer-container {
      background: transparent !important;
    }

    .app-page-header {
      @supports (
        (-webkit-backdrop-filter: blur(64px)) or (backdrop-filter: blur(64px))
      ) {
        background-color: transparent !important;
        backdrop-filter: blur(64px);
      }
    }

    /* if backdrop-filter support: very transparent and blurred */
    @supports (
      (-webkit-backdrop-filter: blur(32px)) or (backdrop-filter: blur(32px))
    ) {
      .mat-mdc-tab-header {
        background-color: transparent !important;
        backdrop-filter: blur(32px);
      }
    }
  }
}

/****** End Third Theme - Transparent theme ******/

@media print {
  @page {
    size: 330mm 427mm;
    margin: 14mm;
  }

  body,
  mat-sidenav-container,
  #section-to-print {
    display: block !important;
    position: relative !important;
    width: auto !important;
    height: auto !important;
    overflow: visible !important;
    margin: 0 !important;
  }

  mat-tab-header {
    display: none !important;
  }

  .mat-mdc-tab-body-content,
  .mat-mdc-tab-body {
    overflow: visible !important;
  }

  .text-base-text {
    color: black !important;
  }

  mat-sidenav,
  mat-sidenav *,
  mat-toolbar,
  mat-toolbar *,
  app-page-header,
  app-page-header *,
  mat-mdc-tab-header,
  mat-mdc-tab-header *,
  .no-print {
    width: 0 !important;
    height: 0 !important;
    display: none !important;
  }

  .mat-mdc-tab-body-wrapper,
  mat-sidenav-content {
    margin: 0 !important;
  }

  mat-card.mat-card {
    box-shadow: none !important;
  }
}

h2.m-0 {
  margin: 0 !important;
}

// .dashboard {
//   display: flex !important;
// }

// .dashboard app-file-upload > div {
//   display: flex;
//   flex-grow: 1;
// }

// Light scrollbar styling

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: map-get(mat.$light-theme-background-palette, "background");
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: map-get(mat.$light-theme-background-palette, "status-bar");
  border-radius: 16px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: map-get(mat.$light-theme-background-palette, "background");
}

.mat-icon-button.dense {
  width: 20px;
  height: 20px;
  line-height: 20px;
}

.multiline-autocomplete {
  &.mat-mdc-autocomplete-panel mat-option.mat-mdc-option {
    margin: 1rem 0;
    overflow: visible;
    line-height: initial;
    height: initial;
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  .mat-option-text.mat-option-text {
    white-space: normal;
  }
}

mat-chip {
  ::before,
  ::after {
    border-style: none !important;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .page-content-spacing {
    @apply mx-4 my-2 md:mx-8 md:my-4;
  }

  .border-after {
    @apply after:border-t after:border-divider/[0.12] after:content-[""] after:flex-auto after:mb-1;
  }

  .border-before::before {
    content: "";
    position: absolute;
    top: 0;
    left: 22px;
    width: 4px;
    background: #d4ded7bd;
    height: calc(100% - 30px);
    margin-top: 10px;
    z-index: 1;
  }

  .chip {
    @apply font-medium text-xs inline-block py-[2px] rounded min-w-[96px] text-center;
  }

  .circle {
    @apply rounded-full;
  }

  .bg-secondary-card {
    @apply bg-black/[0.04];
  }

  .text-base-text {
    color: black;
  }

  .text-primary-text {
    color: rgba(black, 0.67);
  }

  .text-secondary-text {
    color: rgba(black, 0.54);
  }

  .text-tertiary-text {
    color: rgba(black, 0.54);
  }

  .text-base-inverse {
    color: white;
  }

  .text-hint-text {
    color: rgba(black, 0.38);
  }

  .text-disabled-text {
    color: rgba(black, 0.38);
  }

  .text-icon {
    color: rgba(black, 0.54);
  }

  .text-ready {
    color: var(--ready-color);
  }

  .text-submitted {
    color: var(--submitted-color);
  }

  .text-quoted,
  .text-quote {
    color: var(--quoted-color);
  }

  .text-referred,
  .text-refer {
    color: var(--referred-color);
  }

  .text-declined,
  .text-decline {
    color: var(--declined-color);
  }

  .text-bound {
    color: var(--bound-color);
  }

  .text-issued {
    color: var(--issued-color);
  }

  .text-lost {
    color: var(--lost-color);
  }

  .text-closed {
    color: var(--closed-color);
  }

  .text-missed {
    color: var(--missed-color);
  }

  .text-awaitinginformation {
    color: var(--awaitinginformation-color);
  }

  .text-inprogress {
    color: var(--inprogress-color);
  }

  .text-cancelled,
  .text-cancel {
    color: var(--cancelled-color);
  }

  .copy-text {
    margin-top: 0;
    margin-bottom: 10px;
    cursor: pointer;
    color: #49d62b;
  }

  .dark-theme {
    .bg-secondary-card {
      @apply bg-white/[0.16];
    }

    .text-base-text {
      color: white;
    }

    .text-primary-text {
      color: rgba(white, 0.67);
    }

    .text-secondary-text {
      color: rgba(white, 0.6);
    }

    .text-tertiary-text {
      color: rgba(white, 0.5);
    }

    .text-base-inverse {
      color: black;
    }

    .text-hint-text {
      color: rgba(white, 0.4);
    }

    .text-disabled-text {
      color: rgba(white, 0.4);
    }

    .text-icon {
      color: white;
    }
  }
}

.cdk-overlay-pane {
  transition: width 3s ease-in-out;
}

.custom-wide-select-panel .cdk-overlay-pane {
  width: 500px;
  min-width: 500px;
}

@media (min-width: 1920px) {
  .xl\:block {
    display: block !important;
    visibility: visible !important;
  }
}

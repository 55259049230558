// Import library functions for theme creation.
@use "@angular/material" as mat;

@mixin color($config-or-theme) {
  // Extract the color configuration in case a theme has been passed.
  // This allows consumers to either pass a theme object or a color configuration.
  $config: mat.get-color-config($config-or-theme);

  // Extract the palettes you need from the theme definition.
  $primary: map-get($config, primary);

  // $accent: map-get($config, accent);
  $foreground: map-get($config, foreground);

  // Define any styles affected by the theme.
  // .custom-slider {
  //   // Use mat-color to extract individual colors from a palette.
  //   // background-color: mat-color($primary);
  //   // border-color: mat-color($accent, A400);
  // }
}

@mixin typography($config-or-theme) {
  // Extract the typography configuration in case a theme has been passed.
  $config: mat.get-typography-config($config-or-theme);
}

@mixin theme($theme) {
  // Extracts the color and typography configurations from the theme.
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  .custom-slider {
    .ngx-slider {
      .ngx-slider-bar {
        height: 6px;
      }

      .ngx-slider-pointer {
        width: 20px;
        height: 20px;
        top: -6px;
        background-color: mat.get-color-from-palette(mat.$grey-palette, 600);
      }

      .ngx-slider-pointer::after {
        display: none;
      }

      .ngx-slider-bubble {
        bottom: 14px;
      }

      .ngx-slider-tick {
        width: 4px;
        height: 4px;
        margin-left: 4px;
        background: mat.get-color-from-palette(mat.$grey-palette, 600);
        top: 4px;
      }

      .ngx-slider-tick.ngx-slider-selected {
        background: mat.get-color-from-palette(mat.$grey-palette, 900);
      }
    }

    &.left-Quote .ngx-slider .ngx-slider-bar-wrapper.ngx-slider-left-out-selection .ngx-slider-bar,
    &.center-Quote .ngx-slider .ngx-slider-selection,
    &.right-Quote .ngx-slider .ngx-slider-bar-wrapper.ngx-slider-right-out-selection .ngx-slider-bar {
      background: var(--quoted-color) !important;
    }

    &.left-Decline .ngx-slider .ngx-slider-bar-wrapper.ngx-slider-left-out-selection .ngx-slider-bar,
    &.center-Decline .ngx-slider .ngx-slider-selection,
    &.right-Decline .ngx-slider .ngx-slider-bar-wrapper.ngx-slider-right-out-selection .ngx-slider-bar {
      background: var(--declined-color) !important;
    }

    &.left-Refer .ngx-slider .ngx-slider-bar-wrapper.ngx-slider-left-out-selection .ngx-slider-bar,
    &.right-Refer .ngx-slider .ngx-slider-bar-wrapper.ngx-slider-right-out-selection .ngx-slider-bar,
    &.center-Refer .ngx-slider .ngx-slider-selection {
      background: var(--referred-color) !important;
    }
  }

  // Do not generate styles if configurations for individual theming
  // systems have been explicitly set to `null`.
  @if $color {
    @include color($color);
  }

  @if $typography {
    @include typography($typography);
  }
}

// Import library functions for theme creation.
@use "@angular/material" as mat;

@mixin color($config-or-theme) {
  // Extract the color configuration in case a theme has been passed.
  // This allows consumers to either pass a theme object or a color configuration.
  $config: mat.get-color-config($config-or-theme);

  // Extract the palettes you need from the theme definition.
  $primary: map-get($config, primary);

  // $accent: map-get($config, accent);
  $foreground: map-get($config, foreground);

  // Define any styles affected by the theme.
  // app-vector-entity {
  //   // Use mat-color to extract individual colors from a palette.
  //   // background-color: mat-color($primary);
  //   // border-color: mat-color($accent, A400);
  // }
}

@mixin typography($config-or-theme) {
  // Extract the typography configuration in case a theme has been passed.
  $config: mat.get-typography-config($config-or-theme);
}

@mixin theme($theme) {
  // Extracts the color and typography configurations from the theme.
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  .quote-engine-status-outcome-select {
    .mdc-line-ripple::before {
      border-bottom-width: 2px;
    }

    &.Quote .mdc-line-ripple::before {
      border-color: var(--quoted-color) !important;
    }

    &.Refer .mdc-line-ripple::before {
      border-color: var(--referred-color) !important;
    }

    &.Decline .mdc-line-ripple::before {
      border-color: var(--declined-color) !important;
    }
  }

  // Do not generate styles if configurations for individual theming
  // systems have been explicitly set to `null`.
  @if $color {
    @include color($color);
  }

  @if $typography {
    @include typography($typography);
  }
}

/* stylelint-disable */
@font-face {
  font-family: vertigo-icons;
  src: url("fonts/vertigo-icons.eot?yjyqbq");
  src: url("fonts/vertigo-icons.eot?yjyqbq#iefix") format("embedded-opentype"),
    url("fonts/vertigo-icons.ttf?yjyqbq") format("truetype"),
    url("fonts/vertigo-icons.woff?yjyqbq") format("woff"),
    url("fonts/vertigo-icons.svg?yjyqbq#vertigo-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: vertigo-icons !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-vector::before {
  content: "\e900";
}

.icon-void::before {
  content: "\e901";
}

.icon-vortex::before {
  content: "\e902";
}
/* stylelint-enable */

@use "@angular/material" as mat;

@mixin color($config-or-theme) {
  // Extract the color configuration in case a theme has been passed.
  // This allows consumers to either pass a theme object or a color configuration.
  $config: mat.get-color-config($config-or-theme);

  // Extract the palettes you need from the theme definition.
  $primary: map-get($config, primary);
  $accent: map-get($config, accent);
  $foreground: map-get($config, foreground);
  $background: map-get($config, background);

  // Define any styles affected by the theme.
  agency-submission-overview {
    .step .section-content {
      border-color: map-get($foreground, base);
    }

    .step h2 span.number {
      background-color: map-get($primary, 500);
      color: black;
    }

    .quoted {
      background: var(--quoted-color);
    }

    .referred {
      background: var(--referred-color);
    }

    .declined {
      background: var(--declined-color);
    }
  }
}

@mixin typography($config-or-theme) {
  // Extract the typography configuration in case a theme has been passed.
  $config: mat.get-typography-config($config-or-theme);
}

@mixin theme($theme) {
  // Extracts the color and typography configurations from the theme.
  $color: mat.get-color-config($theme);
  $typography: mat.get-typography-config($theme);

  // Do not generate styles if configurations for individual theming
  // systems have been explicitly set to `null`.
  @if $color {
    @include color($color);
  }

  @if $typography {
    @include typography($typography);
  }
}

@use "@angular/material" as mat;

@mixin color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $background: map-get($config, background);
  $primary: map-get($config, primary);

  .policy-type-filter {
    .vicon {
      transition: all 0.1s ease;
    }

    .vicon:hover {
      $card-background: map-get($background, card);

      background: rgba(red($card-background), green($card-background), blue($card-background), 0.5) !important;
    }

    .vicon-selected {
      background: map-get($background, card) !important;
      color: mat.get-color-from-palette($primary);
    }

    .vicon-large {
      width: 58px;
      height: 58px;
      font-size: 38px;
      border-radius: 4px;
    }
  }
}

@mixin theme($theme) {
  // Extracts the color and typography configurations from the theme.
  $color: mat.get-color-config($theme);

  // Do not generate styles if configurations for individual theming
  // systems have been explicitly set to `null`.
  @if $color {
    @include color($color);
  }
}
